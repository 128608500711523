import { useLodgingStore } from '~/store/lodging'

export const useApiUsers = () => {
  const config = useRuntimeConfig()

  return {
    index: (filters: UserFilters) => {
      return useFetch<User[]>(`/users`, {
        baseURL: config.public.apiBaseUrl,
        method: 'GET',
        query: filters
      })
    },
    indexAll: (lodgingId: number) => {
      return useFetch<UserTag[]>(`/users/by-lodging/${lodgingId}`, {
        baseURL: config.public.apiBaseUrl,
        method: 'GET',
      })
    },
    indexLodgingOperators: (filters: any) => {
      return useFetch<User[]>(`/users/operators`, {
        baseURL: config.public.apiBaseUrl,
        method: 'GET',
        query: filters
      })
    },
    show: (id: number) => {
      return useFetch<User>(`/users/${id}`, {
        baseURL: config.public.apiBaseUrl,
        method: 'GET'
      })
    },
    store: (data: UserForm) => {
      return $fetch<User>(`/users`, {
        baseURL: config.public.apiBaseUrl,
        method: 'POST',
        body: data
      })
    },
    update: (id: number, data: UserForm) => {
      return $fetch<User>(`/users/${id}`, {
        baseURL: config.public.apiBaseUrl,
        method: 'PUT',
        body: data
      })
    },
    destroy: (id: number) => {
      return $fetch<User>(`/users/${id}`, {
        baseURL: config.public.apiBaseUrl,
        method: 'DELETE'
      })
    },
    uploadImage: (id: number, file: FileList) => {
      const formData = new FormData()
      formData.append('file', file[0])
      return $fetch<User>(`/users/${id}/image/upload`, {
        baseURL: config.public.apiBaseUrl,
        method: 'POST',
        body: formData
      })
    },
    changePassword: (id: number, data: UserPasswordForm) => {
      return $fetch<User>(`/users/${id}/change-password`, {
        baseURL: config.public.apiBaseUrl,
        method: 'PUT',
        body: data
      })
    },

    // Utente loggato
    changeLanguage: (language: string) => {
      return $fetch(`/users/change-language`, {
        baseURL: config.public.apiBaseUrl,
        method: 'PUT',
        body: {
          language: language
        }
      })
    }
  }
}
